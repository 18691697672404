/**
 * @fileOverView
 * @description Sound Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var moment = require('moment');
var CreateVybButton = require('../templates/components/createVybButton.html');
var UseSoundButton = require('../templates/components/useSoundButton.html');
var Log = require('./log');
var app = require("ampersand-app");

var Sound = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/sound";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        name : 'string',
        description: 'string',
        type: 'string',
        status: 'string',
        originalUri: 'string',
        meta: 'object',
        createdAt: 'string'
    },

    session: {
        mode: ['string',false,'sounds'],
        pinging : ['boolean',true,false],
        isVyb: ['boolean',true,false],
        toggle: ['boolean',true,false]
    },

    derived: {
        soundFile: {
            deps: ['key'],
            fn: function(){
                return this.key + "." + this.type;
            }
        },

        soundObject: {
            deps: ['soundFile'],
            fn: function(){
                var file = this.key=="none" ? null : app.config.soundLib + '/' + this.soundFile;
                var sobject = new Audio(file);
                sobject.volume = 1.0;
                sobject.key = this.key;
                sobject.onended = function(){
                    if(app.soundViews[this.key]!=undefined) app.soundViews[this.key].set('pinging',false);
                    app.eventLog.add('play sound ' + this.key + ' completed');
                };
                return sobject;
            }
        },

        soundObjectLocal: {
            deps: ['soundFile'],
            fn: function(){

                var self = this;

                if(app.platform=="browser") return null;

                var soundLib = app.platformId=="android" ? app.config.androidSoundLib + "Notifications/" : app.config.iOsSoundLib;
                var file = this.key=="none" ? null : soundLib + '/' + this.soundFile;

                if(app.platformId=="android"){
                    var sobject = new Audio(file);
                    sobject.volume = 1.0;
                    sobject.key = this.key;
                    sobject.onended = function(){
                        if(app.soundViews[self.key]!=undefined) app.soundViews[self.key].set('pinging',false);
                        app.eventLog.add('play local sound ' + self.key + ' completed');
                    };
                    sobject.onerror = function(){
                        if(app.soundViews[self.key]!=undefined) app.soundViews[self.key].set('pinging',false);
                        console.log('error playing local sound file ' + file);
                        console.log(this.error);
                        self.soundObject.play();
                    };

                }else if(app.platformId=="ios"){
                    var sobject = new Media(
                        file,
                        function(){
                            console.log('play local sound ' + self.key + ' completed');
                            if(app.soundViews[self.key]!=undefined) app.soundViews[self.key].set('pinging',false);
                        },
                        function(err){
                            console.log('error playing local sound ' + self.key);
                            console.dir(err);
                            if(app.soundViews[self.key]!=undefined) app.soundViews[self.key].set('pinging',false);
                            self.soundObject.play();
                        }
                    );
                    sobject.setVolume('1.0');
                }

                return sobject;
            }
        },

        vybKey: {
            deps: ['key','mode','toggle'],
            fn: function(){

                if(app.vybs==undefined) return null;

                var vyb = app.vybs.get(this.key, "soundKey");
                return vyb !=undefined && vyb!=null ? vyb.key : null;
            }
        },

        vybName: {
            deps: ['key','mode','toggle','vybKey'],
            fn: function(){
                var vyb = app.vybs!=undefined ? app.vybs.get(this.key, "soundKey") : null;
                var content = "";
                if(vyb!=null && vyb!=undefined) content = 'used by <div class="vused">' + vyb.name + '</div>';
                else if(this.mode=='vyb' || this.mode=='vybfollow') content =  UseSoundButton();
                else content = CreateVybButton();
                return content;
            }
        },

        used : {
            deps: ['key','mode','toggle'],
            fn: function(){
                var vyb = app.vybs!=undefined ? app.vybs.get(this.key, "soundKey") : null;
                return vyb!=null && this.mode=='vyb' ? true : false;
            }
        },

        creator : {
            deps: ['meta','status','originalUri','mode','toggle'],
            fn: function(){

                var creator = "";

                if(this.status=="private" && this.originalUri!=undefined && this.createdAt!=undefined){

                    var origUri = this.originalUri;

                    //downloads
                    if(this.meta!=undefined && this.meta.source!=undefined && this.meta.source=="downloaded"){
                        origUri = 'downloaded ' + origUri;

                    //recordings and uploads
                    }else{
                        origUri = this.originalUri.replace("/"," file ");
                    }

                    var offset = moment().utcOffset();
                    var dtUpload = moment.utc(this.createdAt).utcOffset(offset).format('MMM DD, YYYY [at] h:mm a');

                    creator = 'private: ' + origUri + ' on ' + dtUpload;

                    if(app.vybs!=undefined && app.vybs.get(this.key, "soundKey")==null){
                        var deleteButton = ' <span class="vsounddel">&#9447;</span>';
                        creator += deleteButton;
                    }

                }else if(this.meta!=null && this.meta.username!=undefined){
                    creator = 'by <a class="vcreator" onclick="app.utils.cancelBubble()" target="_new" href="'+this.meta.source+'">' + this.meta.username + ' at freesound.org</a>. ';
                }

                return creator;
            }
        },

        descriptionDisplay: {

            deps: ['description'],
            fn: function(){
                var el = document.createElement('div');
                el.innerHTML = this.description!=undefined ? this.description : "";
                var text = el.innerText;
                delete el;
                return text;
            }
        },

        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        }
    },

    playSound: function(isVyb=false){

        this.isVyb = isVyb;

        if(app.platform=="app" && app.usedSounds.get(this.key)) this.soundObjectLocal.play();
        else this.soundObject.play();
        app.eventLog.add('playing sound ' + this.key);

    }
});

module.exports = Sound;
