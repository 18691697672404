/**
 * @fileOverView
 * @description file system utilities. <br>
 * @copyright 2015-present Flatirontek LLC
 **/

'use strict';
var app = require('ampersand-app');

module.exports = {

    //list files in a directory
    listDir: function (path) {
        window.resolveLocalFileSystemURL(path,
            function (fileSystem) {
                var reader = fileSystem.createReader();
                reader.readEntries(
                    function (entries) {
                        entries.forEach(function(entry) {
                            if (entry.isDirectory) {
                                // Recursively list files within the directory
                                app.fsutils.listDir(entry.fullPath);
                            } else {
                                console.log("File:", entry.fullPath);
                            }
                        });
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }, function (err) {
                console.log(err);
            }
        );
    },

    //create a directory
    createDir: function(rootDir,subDir){
        window.resolveLocalFileSystemURL(rootDir,
            function (rootDirEntry) {
                console.log(rootDirEntry)

                rootDirEntry.getDirectory(subDir,
                    {create: true},
                    function (dirEntry) {
                        console.log('created ' + subDir);
                        console.log(dirEntry);
                    },
                    function (err) {
                        console.log('create subDir error:');
                        console.log(err);
                    }
                );
            },
            function (err) {
                console.log('resolveLocalFileSystemURL error');
                console.log(err);
            }
        );
    },

    //delete a directory
    deleteDirectory: function (dirName) {

        const dataDir = app.platformId === 'android' ? app.config.androidSoundLib : cordova.file.dataDirectory;

        const uri = dataDir + dirName;
        window.resolveLocalFileSystemURL(uri, (dirEntry) => {
            dirEntry.removeRecursively(
                () => console.log('successfully deleted the directory ' + dirName + ' and its content'),
                e => console.error('there was an error deleting the directory ' + dirName, e.toString())
            )
        });
    },

    //delete file
    deleteRecordedFiles: function(targetDir, fileWildcard){

        window.resolveLocalFileSystemURL(targetDir,
            function (fileSystem) {
                var reader = fileSystem.createReader();
                reader.readEntries(
                    function (entries) {
                        console.log('deleteRecordedFiles begin in ' + targetDir);
                        entries.forEach(entry => {
                            if(entry.name.match(fileWildcard)!=null){
                                entry.remove(function(file) {
                                        console.log(entry.name + ' deleted');
                                    },
                                    function(error){
                                        console.log('error deleting recorded file ' + entry.name);
                                    });
                            }
                        });
                    },
                    function (err) {
                        console.log('error in deleteRecordedFiles');
                        console.log(err);
                    }
                );
            }, function (err) {
                console.log('deleteRecordedFiles error finding ' + targetDir);
                console.log(err);
            }
        );
    },

    //delete file
    deleteFile: function(targetDir, fileName){
        window.resolveLocalFileSystemURL(targetDir,
            function (fileSystem) {
                fileSystem.getFile(fileName, {create:false}, function(fileEntry) {
                    fileEntry.remove(function(){
                        console.log('successfully removed file ' + fileName);
                    },function(error){
                        console.log('error removing file ' + fileName);
                        console.dir(error);
                    },function(){
                        console.log(fileName + ' was not found in ' + targetDir);
                    });
                });
            }, function (err) {
                console.log('deleteFile error finding ' + targetDir);
                console.log(err);
            }
        );
    },

    //download a URL file into a directory
    downloadFile: function (targetDir, fileURL) {

        var self = this;
        window.resolveLocalFileSystemURL(targetDir,
            function (targetDirEntry) {
                self.retrieveFile(targetDirEntry, fileURL);
            },
            function (err) {
                console.log('resolveLocalFileSystemURL error for ' + targetDir);
                console.log(err);
            }
        );
    },

    retrieveFile: function (targetDirEntry, fileURL) {

        var self = this;
        var fileName = fileURL.split("/").pop();
        var xhr = new XMLHttpRequest();
        console.log('retrieving file ' + fileURL);
        xhr.open('GET', fileURL, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (this.status == 200) {

                var blob = new Blob([this.response], {type: 'audio/mpeg'});
                self.saveFile(targetDirEntry, blob, fileName);
            }
        };
        xhr.send();
    },

    saveFile: function (dirEntry, fileData, fileName) {
        var self = this;

        dirEntry.getFile(
            fileName,
            {create: true, exclusive: false},
            function (fileEntry) {
                console.log('saving file ' + fileName);
                self.writeFile(fileEntry, fileData, fileName);

            },
            function (err) {
                console.log('error in saveFile dirEntry.getFile');
                console.log(err);
            }
        );
    },

    writeFile: function(fileEntry,fileData, fileName){

        fileEntry.createWriter(function (fileWriter) {

            fileWriter.onwriteend = function() {
                console.log("successfully saved file " + fileName);
            };

            fileWriter.onerror = function(e) {
                console.log("Failed to save file ' + fileName + ' in writeFile: " + e.toString());
            };

            fileWriter.write(fileData);
        });
    },

    //create empty file
    createFile: function(targetDir,targetFile){
        window.resolveLocalFileSystemURL(targetDir, function (dir) {
                dir.getFile(targetFile, {create: true}, function (fileEntry) {
                        console.log('created file ' + targetFile);
                        console.dir(fileEntry);

                        console.log('internal url is ');
                        console.log(fileEntry.toInternalURL());
                        return fileEntry;
                    },
                    function(err){
                        console.log('error creating file ' + targetDir + targetFile);
                        console.dir(err);
                    });
            },
            function(err){
                console.log('error reading target dir ' + targetDir);
                console.dir(err);
            });
    },

    //read file
    readFile: function(targetDir,targetFile){
        window.resolveLocalFileSystemURL(targetDir, function (dir) {
            dir.getFile(targetFile, {create: false}, function (fileEntry) {
                console.log('fileEntry for ' + targetFile);
                console.dir(fileEntry);

                console.log('internal url is ');
                console.log(fileEntry.toInternalURL());
                return fileEntry;
            });
        });
    },

    soundSetUp: function(sound){

        if(app.platform=="browser" || sound.key=="undefined" || sound.name=="undefined" || sound.soundFile=="undefined") return;

        console.log('setting up sound ' + sound.name);

        if(app.platformId=="ios"){
            this.loadIosSound(sound);

        }else if(app.platformId=="android"){
            this.loadAndroidSound(sound);
        }
    },

    loadIosSound: function(sound){
        var rootDir = cordova.file.applicationStorageDirectory + "Library";
        var soundDir = "Sounds";
        this.loadSound(rootDir,soundDir,sound);
    },

    loadAndroidSound: function(sound){
        var rootDir = cordova.file.externalRootDirectory;
        var soundDir = "Notifications";
        this.loadSound(rootDir,soundDir,sound);
        this.createAndroidPushChannel(sound);
    },

    loadSound: function(rootDir,soundDir,sound){
        var self = this;
        var soundUri = app.config.soundLib + "/" + sound.soundFile;

        console.log(`soundUri is ${rootDir}/${soundDir}/${sound.soundFile}`);
        console.log('soundUri is ' + soundUri);

        window.resolveLocalFileSystemURL(
            rootDir,

            function (rootDirEntry) {
                rootDirEntry.getDirectory(
                    soundDir,
                    { create: true },
                    function (soundDirEntry) {

                        soundDirEntry.getFile(
                            sound.soundFile,
                            { create: false, exclusive: false},
                            function(soundFileEntry){
                                console.log(sound.soundFile + ' already loaded');
                            },
                            function(err){
                                console.log(sound.soundFile + ' does not exist, retrieving');
                                self.retrieveFile(soundDirEntry,soundUri);
                            }
                        );
                    },
                    function(err){
                        console.log('getDirectory error for ' + soundDir + ' in loadSound');
                    }
                );
            },

            function (err) {
                console.log('resolveLocalFileSystemURL error in loadSound');
                console.log(err);
            }
        );

    },

    loadSoundFromKey: function(soundKey){
        var self = this;

        console.log('loading sound from key ' + soundKey);
        var soundFile = soundKey + ".mp3";

        if(app.usedSounds!=undefined && app.usedSounds.get(soundKey,'key')){
            soundFile = app.usedSounds.get(soundKey,'key').get('soundFile');
            console.log(soundFile + ' found in app.usedSounds');
        }else if(app.sounds!=undefined && app.sounds.get(soundKey,'key')){
            soundFile = app.sounds.get(soundKey,'key').get('soundFile');
            console.log(soundFile + ' found in app.sounds');

        }else{
            var sound = new app.Sound({key: soundKey});
            console.log('retrieving sound ' + soundKey + ' for loadSoundFromKey');
            sound.fetch({
                success: function(model,response){
                    console.log('retrieved sound ' + model.soundFile);
                    self.loadSoundFromFile(soundKey,model.soundFile);

                },
                error: function(){
                    console.log('error retrieving sound ' + soundKey + ' in loadSoundFromKey');
                }
            });
            return;
        }

        this.loadSoundFromFile(soundKey,soundFile);
    },

    loadSoundFromFile: function(soundKey,soundFile){
        var self = this;
        if(app.platform=="browser") return;
        var sound = {soundKey: soundKey, soundFile : soundFile};

        if(app.platformId=="ios"){
            this.loadIosSound(sound);

        } else if(app.platformId=="android") {

            console.log('retrieving sound ' + soundKey);
            var sound = new app.Sound({key: soundKey});
            sound.fetch({
                success: function (model, response) {
                    self.loadAndroidSound(model);

                },
                error: function (error) {
                    console.log('loadSoundFromKey error:', error);
                }
            });
        }
    },

    uploadSoundFile: function(data){
        var self = this;
        var view = data.view;

        const fileDir = app.platformId == "android" ? cordova.file.cacheDirectory : 'cdvfile://localhost/temporary/'
        var fileURL = fileDir + data.soundFileName;

        var win = function (r) {
            console.log("Code = " + r.responseCode);
            console.log("Response = " + r.response);
            console.log("Sent = " + r.bytesSent);

            console.log('sound successfully uploaded');
            var soundData = JSON.parse(r.response);
            var sound = new app.Sound(soundData);
            sound.set('mode', app.vyb!=undefined && app.vyb!=null ? 'vyb' : 'sounds');
            app.sounds.add(sound,{at:0});
            self.soundSetUp(sound);
            view.query('[id="nav-search-tab"]').click();
            view.resetSound();
        };

        var fail = function (error) {
            alert("An error has occurred:");
            console.dir(error);
            view.message("error saving sound file","error");
            view.resetButtons();
        };

        var options = new FileUploadOptions();
        options.fileKey = "file";
        options.fileName = fileURL.substr(fileURL.lastIndexOf('/') + 1);
        options.mimeType = "audio/mpeg";
        options.headers = {'Authorization' : app.auth.jwtToken};

        var params = {};
        params.name = data.name;
        params.description = data.description;
        params.source = data.source;
        params.fileName = data.soundFileName;
        params.type = data.soundFileType;

        options.params = params;

        var ft = new FileTransfer();
        ft.upload(fileURL, encodeURI(app.config.restApiUrl + '/audiofile'), win, fail, options);
    },

    createAndroidPushChannel: function(sound){
        if(sound==undefined || sound.key==undefined || sound.soundFile==undefined || app.platformId!="android") return;

        var channel = {
            id: sound.key,
            name: sound.name,
            file: sound.soundFile,
            importance: 5,
            vibration: [500, 500, 500, 1000],
            sound: sound.key,
            visibility: 1,
            //light: true,
            //lightColor: parseInt("FFFF8C00", 16).toString(),
            badge: false
        };

        console.log(channel);

        cordova.plugins.firebase.messaging.createChannel(channel)
            .then( () => {
                console.log('successfully created channel ' + sound.key);
            })
            .catch((err) => {
                console.log('error creating channel ' + sound.key);
                console.log(err);
            });
    },

    createDefaultPushChannel: function(){

        var channel = {
            id: app.config.defaultSoundKey,
            name: "Vybit Default Channel",
            file: app.config.defaultSoundKey + '.mp3',
            description: 'Default Vybit Sound',
            importance: 5,
            vibration: [500, 500, 500, 1000],
            sound: app.config.defaultSoundKey,
            visibility: 1,
            //light: true,
            //lightColor: parseInt("FFFF8C00", 16).toString(),
            badge: false
        };

        console.log('vybit default channel will be:');
        console.dir(channel);

        if(app.platformId=="android"){
            cordova.plugins.firebase.messaging.createChannel(channel)
                .then( () => {
                    console.log('successfully set default channel Vybit Default Channel');
                })
                .catch( (error) => {
                    console.log('Set Vybit Default Channel error: ' + error);
                });
        }

        console.log('loading default sound ' + app.config.defaultSoundKey);
        this.loadSoundFromKey(app.config.defaultSoundKey);
    },

    createSilentChannel: function(){

        var channel = {
            id: 'none',
            name: "Vybit Silent Channel",
            description: 'Silent Vybit Sound',
            file: 'none.mp3',
            importance: 5,
            vibration: [500, 500, 500, 1000],
            sound: 'none',
            visibility: 1,
            //light: true,
            //lightColor: parseInt("FFFF8C00", 16).toString(),
            badge: false
        };

        console.log('vybit silent channel will be:');
        console.dir(channel);

        if(app.platformId=="android"){
            cordova.plugins.firebase.messaging.createChannel(channel)
                .then( () => {
                    console.log('successfully set Vybit Silent Channel');
                })
                .catch( (error) => {
                    console.log('Set Vybit Silent Channel error: ' + error);
                });
        }

        console.log('loading silent sound none');
        this.loadSoundFromKey('none');
    }
}
